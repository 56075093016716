@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

body {
	background-color: white !important;
}

#mfe-dev-panel-wrapper {
	background-color: #fafafa;
}

@font-face {
    font-family: 'Noto Sans';
    font-weight: 500;
    src: url('https://storage.googleapis.com/takeoff-micro-frontends/common-static/notosans-medium.woff2') format('woff2'),
        url('https://storage.googleapis.com/takeoff-micro-frontends/common-static/notosans-medium.woff') format('woff');
}

.testing-pars-panel {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.testing-pars-panel-header {
    padding: 15px;
    display: flex;
    flex-direction: column;
    border: 1px solid #cdcdcd;
    cursor: pointer;
}

.blur-svgs svg {
    & rect {
        filter: blur(10px);
        opacity: 0.5;
    }
}

.blur-svgs svg:hover {
    
    & rect {
        filter: none;
        opacity: 1;
    }
}

.top-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding: 20px;
    background-color: white;
}

.barcode-dropdown {
    margin-bottom: 1rem;
}

.box-barcode-list-horizontal {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.section-title {
    font-size: 30px;
}

.section-heading-text {
    font-size: 20px;
}

.section-wrapper {
    min-width: 1000px;
    border-top: outset; 
    border-color: red;
}

.multizone-step-container {
   min-width: 1000px;
    border-top: outset; 
    border-color: red; 
    padding-bottom: 12px;
}

.multizone-tote-container {
    position: relative;
    max-width: 244px;
    margin-right: 6px; 
    margin-bottom: 6px; 
}

.multizone-tote-icon-container {
    right: 0; 
    padding: 3px;
    position: absolute;
    z-index: 1; background-color: white; 
    border-radius: 16px;
    display: flex;
}

.multizone-step-info {
    display: flex;
    flex-direction: row;
    border-bottom-color: lightgray;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    justify-content: center;
}

.multizone-step-left-panel {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    margin-bottom: 12px;
}

.picking-mode {
    color: #616161;
    font-style: italic;
}

.line-note {
    font-size: 14px;
    width: 400psx;
    padding: 12psx;
    background-color: lightyellow;
    border: 1px solid black;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
}